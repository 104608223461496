// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$darkblue-palette: (
  // 50: #E1F4FD,
  100: #01aabc,
  // 200: #80D2F8,
  // 300: #4EC0F4,
  // 400: #2AB3F3,
  500: #091e5a,
  // 600: #0698E1,
  700: #0584CD,
  // 800: #0474B9,
  // 900: #003976
  contrast:(100: #efcdb4,
    500: #fff,
    700: #ba5c00)
);

$lightblue-palette: (
  // 50: #E1F4FD,
  100: #01aabc,
  // 200: #80D2F8,
  // 300: #4EC0F4,
  // 400: #2AB3F3,
  500: #01aabc,
  // 600: #0698E1,
  700: #0584CD,
  // 800: #0474B9,
  // 900: #003976
  contrast:(100: #efcdb4,
    500: #fff,
    700: #ba5c00)
);

$placemaker-red-palette: (
  100: #dd1841,
  500: #ce1141,
  700: #ba093e,
  contrast:(100: #efcdb4,
    500: #fff,
    700: #ba5c00)
);

$SkuMappingTool-primary: mat.define-palette($darkblue-palette, 500);
$SkuMappingTool-accent: mat.define-palette($lightblue-palette, 500);

// The warn palette is optional (defaults to red).
$SkuMappingTool-warn: mat.define-palette($placemaker-red-palette, 500);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$SkuMappingTool-theme: mat.define-light-theme((color: (primary: $SkuMappingTool-primary,
        accent: $SkuMappingTool-accent,
        warn: $SkuMappingTool-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($SkuMappingTool-theme);

@font-face {
  font-family: 'OpenSansRegular';
  src: url('assets/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'OpenSansBold';
  src: url('assets/OpenSans-Bold.ttf');
}

$regular_font_family: 'OpenSansRegular';
$bold_font_family: 'OpenSansBold';

/* You can add global styles to this file, and also import other style files */

h1 {
  font-size: 22px;
  font-family: $bold_font_family;
}

h2 {
  font-size: 20px;
  font-family: $bold_font_family;
}

h3 {
  font-size: 16px;
  font-family: $bold_font_family;
}

h4 {
  font-size: 15px;
  font-family: $regular_font_family;
}

b {
  font-size: 14px;
  font-family: $bold_font_family;
}

span,
p, input, mat-form-field{
  font-size: 14px;
  font-family: $regular_font_family;
}


body {
  margin: 0;
  font-family: $regular_font_family;
  font-size: 14px;
}

.font-14{
  font-size: 14px;
  font-family: $regular_font_family;
}

.flex {
  display: flex;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-end {
  justify-content: end;
}

.justify-content-start {
  justify-content: start;
}

.align-items-center {
  align-items: center;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-0 {
  flex-grow: 0;
}

.p-y-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.m-x-16 {
  margin-left: 16px;
  margin-right: 16px;
  ;
}

.m-y-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mat-mdc-snack-bar-container {
  --mat-mdc-snack-bar-button-color: #ffffff;
  --mdc-snackbar-container-color: #333333;
  text-align: center;

  &.app-notification-error {
    margin-top: 66px;
    --mdc-snackbar-supporting-text-color: #f23a2f;
  }

  &.app-notification-success {
    margin-top: 66px;
    --mdc-snackbar-supporting-text-color: #ffffff;
  }
}

.mdc-text-field--outlined .mdc-floating-label--float-above{
  font-size: .75rem !important;
}